<template>
    <v-list dense nav>
        <template v-if="isViewReady">
            <v-list-item v-if="list.length === 0">No realms.</v-list-item>
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                <RealmListItem :attr="item" :link="!selectOne"></RealmListItem>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
import RealmListItem from '@/components/RealmListItem.vue';

export default {
    components: {
        RealmListItem,
    },

    props: {
        // optional; use this when you want to use the realm list to select an realm; emits 'selected' when user taps on an realm
        selectOne: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        list: null,
    }),

    computed: {
        isViewReady() {
            return Array.isArray(this.list);
        },
    },

    methods: {
        async loadRealmList() {
            try {
                this.$store.commit('loading', { loadRealmList: true });
                const response = await this.$client.account(this.$route.params.accountId).realm.list({ accountId: this.$route.params.accountId });
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadRealmList failed', err);
            } finally {
                this.$store.commit('loading', { loadRealmList: false });
            }
        },
        onClickItem(realmId) {
            if (this.selectOne) {
                this.$emit('selected', { realmId });
            }
        },
    },

    mounted() {
        this.loadRealmList();
    },
};
</script>
