<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link>
                </p>
                </v-col>
            </v-row> -->

            <!-- TODO: add an "account overview" acard with other things... need link to manage account at unicorn springs, at least. -->
            <!-- <v-row justify="center" class="pt-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="my-6">
                        <v-app-bar color="indigo" dark flat dense>
                            <v-app-bar-title>Account overview</v-app-bar-title>
                            <v-spacer/>
                        </v-app-bar>
                        <v-card-text>
                            <a href="#" @click="redirectToUnicornSprings">Manage account billing</a>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row> -->

            <!-- TODO: add a "users" card with list of admin users linked to the account; or maybe that goes in unicorn springs?? -->

            <v-row justify="center" class="pt-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="my-6">
                        <v-app-bar color="indigo" dark flat dense>
                            <v-app-bar-title>Realms</v-app-bar-title>
                            <v-spacer/>
                            <v-btn icon :to="{ name: 'account-create-realm', params: { accountId: this.$route.params.accountId } }">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                            </v-btn>
                        </v-app-bar>
                        <RealmList @selected="onSelectRealm" create/>
                    </v-card>
                </v-col>
            </v-row>

        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import RealmList from '@/components/account-dashboard/RealmList.vue';

export default {
    components: {
        RealmList,
    },
    data: () => ({
        account: null,
        error: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        name() {
            return this.account?.name ?? 'Unknown';
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).self.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
    },
};
</script>
